<template>
  <div>
     <div v-if="is_pc == true">
      <van-row type=:flex justify="center">
        <van-col span="4"></van-col>
        <van-col span="13">
          <van-tag type="primary" class="top-tag">内涵段子</van-tag>
        </van-col>        
        <van-col span="4">
          <van-button plain type="primary" size="small" 
            @click="reflash()"
            >more</van-button></van-col>
      </van-row>

<van-divider dashed />

<!-- <van-divider contentPosition="center">{{aritcle[wz_currt].title}}</van-divider> -->

  <van-row type="flex" class="note-row">
        <van-col span="1"></van-col>
        <van-col style="line-height:120px" span="22">
         <p>{{aritcle[wz_currt].content}}</p>
        </van-col>
        <van-col span="1"></van-col>
      </van-row>

     </div>
    <div v-else>
    </div>

<van-divider contentPosition="right" ><a style="font-size:18px" href="https://beian.miit.gov.cn" target="_blank" rel="nofollow">粤ICP备2021005929号</a></van-divider>

  </div>
</template>

<script>

import {Button} from 'vant'
import { Col, Row, Tag,  Divider  } from 'vant';

export default {
 inject:['reload'],
  components: {
    vanButton:Button,
    vanCol:Col,
    vanRow:Row,
    vanTag:Tag,
    vanDivider:Divider 
  },

  data() {
    return {
      aritcle:[
        {title:'1',content:'别人都是笑起来很好看，但是你却不一样，你是看起来很好笑。'},
        {title:'2',content:'总有一款适合你：人丑却颜控，家贫还追星。友少偏爱宅，女汉发神经。蠢材不努力，懒虫盼巅峰。肥婆多吃货，单身总矫情。'},
        {title:'3',content:'当生活中遇到倒霉的事情，千万不要沮丧，打起精神来，你要相信，更倒霉的还在后头。'},
        {title:'4',content:'不经历星期一上午的崩溃，就不知道星期五下午的可贵。'},
        {title:'5',content:'几个字总结我现在的状态：胖的专心致志，穷的心灵祥和！'},
        {title:'6',content:'当你觉得自己又丑又穷，一无是处时，别绝望，因为至少你的判断还是对的。'},
        {title:'7',content:'不要总看别人表面上一帆风顺，实际上，他们背地里，也是一帆风顺。'},
        {title:'8',content:'我是一个善于反省自己的人，比如我反手给你一巴掌之后，我会想想自己是不是打轻了。'},
        {title:'9',content:'经过十年不断的努力和奋斗，我终于从一个懵懂无知的少年变成了一个懵懂无知的青年。'},
        {title:'10',content:'女追男隔层纱，男追女隔层妈，搞不好还隔辆车，隔套房呢。'},
        {title:'11',content:'以前我以为钱可以买到一切，但是我后来发现，我钱不够。'},
        {title:'12',content:'女孩子就是不能惯着，你越跟她解释她越来劲，你当场给她跪下，她可不就没话说了嘛！'},
        {title:'13',content:'好看的锁骨千篇一律，有趣的肚腩弹来弹去。'},
        {title:'14',content:'年轻人嘛，现在没钱算什么，以后没钱的日子还多着呢。'},
        {title:'15',content:'到了我这个年纪，会在你耳边轻轻唱歌，喜欢你的肉体，还会送你包包的，就只剩下蚊子了。'},
        {title:'16',content:'说真的，每次遇到那种不是很好使的感应水龙头，都感觉自己好像要饭的。'},
        {title:'17',content:'人生很多事，终究会随着时间好起来，像很多人原本只是胖，时间久了就变好胖。'},
        {title:'18',content:'和喜欢的人在一起轧马路都觉得幸福，和不喜欢的人在一起逛爱琴海都感觉像是要去铲屎。'},
        {title:'19',content:'有时候你不努力一下，你都不知道什么叫绝望。'},
        {title:'20',content:'如果把我的人生拍成一部电影，那么电影名我已经想好了，就叫穷极一生。'},
        {title:'21',content:'当你失败的时候，身边会有一群关心你的人，他们会问你发生了什么事，听听你的失败经验，然后心满意足地离开'},
        {title:'22',content:'这个时代做什么事，门槛都变得好高，想当个宅男，你买得起房子吗？'},
        {title:'23',content:'现在的我们，上不去的是成绩，下不去的是体重，拿得起放不下的是筷子，钻进去出不来的是被窝！'},
        {title:'24',content:'其实古代人挺乐观的，有点闲工夫都琢磨着怎么长生不老；现代人忙完一天的事，静下心来瘫在床上，心里只有四个字：不想活了！'},
        {title:'25',content:'每一个成功的男人背后，都有一个吃饱了没事做的女人。'},
        {title:'26',content:'男人生气就像放炮竹，砰的一声就结束了。女人生气就像点蚊香，持续高温，圈圈循环。'},
        {title:'27',content:'男人：订婚前，像孙子，百依百顺。订婚后，像儿子，学会顶嘴。结婚后，像老子，发号施令。'},
        {title:'28',content:'为什么奶奶喜欢孙媳妇，却不喜欢儿媳妇，因为敌人的敌人就是朋友！'},
        {title:'29',content:'我用一麻袋的钱上大学，换了一麻袋书；毕业了，用这些书换钱，却买不起一个麻袋。'},
        {title:'30',content:'电视都是：给你多少万，离开我女儿。现实都是：给我们多少万，否则离开我女儿。'},
        {title:'31',content:'一般别人问我忙不忙，我都会说忙，根据我的经验，如果你说不忙，那对方十有八九会让你忙起来。'},
        {title:'32',content:'喜欢就去追呗，管人家有没有男朋友干嘛，球队还有守门员呢，球不是照样进嘛。'},
        {title:'33',content:'人啊，长了颗红楼梦的心，却生活在水浒的世界，想交些三国里的桃园弟兄，却总遇到些西游记里的妖魔鬼怪。'},
        {title:'34',content:'有人说世上99%的事情都能用钱解决，但是他们没说的是，解决剩下的1%，需要更多的钱。'},
        {title:'35',content:'长的帅的告白才叫告白，长的丑的男人告白那叫性骚扰——多么痛的领悟。'},
        {title:'36',content:'为什么中国人结婚都非要选个好日子呢？因为结完婚就没好日子过了！'},
        {title:'37',content:'生儿子是生了一个敌人，长大再带回一个仇人。生女儿是生了一个亲人，长大再带回一个仆人。'},
        {title:'38',content:'每次面对成绩单的时候，我都发现我得了白学病。'},
        {title:'39',content:'跟女朋友吵架，她骂我：“你有神经病！”我觉得男人应该浪漫一点，于是含情脉脉地对她说：：“我有你啊。'},
        {title:'40',content:'我最羡慕的就是西游记里的唐僧，都不用自己洗澡，每过两集就有一个妖怪说：小的们，把那个和尚洗干净了。'},
        {title:'41',content:'每次跟别人吵架后，总觉得没发挥好，还想再吵一遍。'},
        {title:'42',content:'你才二十岁，没遇到喜欢的人很正常，越往后你会发现，大概是遇不到了。'},
        {title:'43',content:'承诺就像女人说要减肥一样，经常说却很难做到，一切都是假的话。'},
        {title:'44',content:'永远不要和父母吵架，因为你吵不赢的时候，只有挨骂，但是当你吵得赢的时候，就是挨打了。'},
        {title:'45',content:'所谓复习，就是把不会的东西再确认一遍你确实不会。'},
        {title:'46',content:'别人都说我很胖，其实我只是瘦的不明显。'},
        {title:'47',content:'没钱的时候，老婆兼秘书；有钱的时候，秘书兼老婆。'},
        {title:'48',content:'不要说我胖，不然我会认为你在嫉妒我比你吃得好。'},
        {title:'49',content:'知道你为什么单身吗？因为在你丑的同时，还嫌弃别人丑！'},
        {title:'50',content:'在这个薄情年代，要想别人对你念念不忘，最好的办法就是欠钱不还。'},
        {title:'51',content:'别人分手了可以一个人去巴黎，而我分手了只能去楼下的牛肉面馆，吃一碗六块钱的牛肉面，还不敢加蛋。'},
        {title:'52',content:'如果我哪里做得不够好，请说出来，千万不要憋出病来，反正我也不会改。'},
        {title:'53',content:'喜欢一个人的时候，大脑会自动加滤镜，美白，磨皮，不喜欢的时候秒变原图。'},
        {title:'54',content:'命运真是幽默，我爱你，你爱他！'},
        {title:'55',content:'好好反思一下自己，你要是长得跟自拍一样，又怎么会没男朋友？'},
        {title:'56',content:'女人之美，在于蠢得无怨无悔；男人之美，在于说谎说得白日见鬼。'},
        {title:'57',content:'以前车马很慢，书信很远，一生只够爱一个人；现在网络很快，老公很多，却死活没有男朋友。'},
        {title:'58',content:'女人一生最喜欢两朵花：一是有钱花，二是尽量花！'},
        {title:'59',content:'你已经是二十几岁的人了，你应该学会吃喝嫖赌，而不是吃恋爱的苦。'},
        {title:'60',content:'女孩子应该学会心疼自己的男朋友，当男朋友太忙不能陪自己的时候，就该去找别的男孩子陪自己，不要让自己的男朋友受累，要做个懂事的女孩。'},
        {title:'61',content:'冲冠一怒为红颜，红颜一笑为了钱！'},
        {title:'62',content:'男生单身久了看谁都是貂蝉，女生单身久了看谁都是渣男。'},
        {title:'63',content:'安慰别人的时候一套一套的，安慰自己的时候只想找根绳子一套。'},
        {title:'64',content:'只要有快递还在路上，就感觉这生活还算有点希望。'},
        {title:'65',content:'胖子只有两条出路，要不就让身材变好，要不就让心态变好。'},
        {title:'66',content:'昨天我的包被抢了，我很伤心，为了这件事我哭了这一晚上，我实在想不通，我到底哪里不如我的包。'},
        {title:'67',content:'吃货的日常状态：嘴里很享受，心里很想瘦。'},
        {title:'68',content:'每一个成功的男人背后，都有一个吃饱了没事做的女人。'},
        {title:'69',content:'世界上最遥远的距离不是生与死，也不是相爱不知，而是我们坐在一起，你却在低头玩手机。'},
        {title:'70',content:'现在不努力，以后别人壁咚的墙都是你搬的砖。'},
        {title:'71',content:'有的人说话就像炒菜，不添油加醋就觉得没滋味。'},
        {title:'72',content:'听说每个中国人平均每天摸15次手机，我不是，我就一次，睡醒拿起，睡前放下。'},
        {title:'73',content:'一场说走就走的旅行，背后势必隐藏着一笔想取就取的钱。'},
        {title:'74',content:'人最大的痛苦莫过于经历了大风大浪，不但没看见彩虹，结果还得了风湿。'},
        {title:'75',content:'当一两个人说我丑时，我不以为然，但是当越来越多的人说我丑时，我知道了事情的严重性：现在的骗子真是越来越多了。'},
        {title:'76',content:'谁说男女生没有纯洁的友谊？只要长得丑，四海之内皆朋友！'},
        {title:'77',content:'女人的一生，小时候淘气，长大了淘宝，工作了淘金，结婚了淘米，老了，淘汰。'},
        {title:'78',content:'挨饿这事，干得好就叫减肥；偷懒这事，干得好就叫享受；死皮赖脸这事，干得好就叫执着；装傻这事，如果干得好，那叫大智若愚。'},
        {title:'79',content:'长得丑怎么了啊？只要不照镜子，恶心的又不是我自己。'},
        {title:'80',content:'女人，小时候有爸爸疼，长大了有老公疼，老了有儿子疼。男人，小时候听妈妈的话，长大了听老婆的话，老了听女儿的话。'},
        {title:'81',content:'当幸福来敲门的时候，我怕我不在家，所以一直都很宅。'},
        {title:'82',content:'女孩子晚上千万不要一个人出门，真的很危险，满大街的烧烤夜宵甜点，没人劝阻，忍不住随便进一家，就要长胖好几斤。'},
        {title:'83',content:'你过得好不好，别人不知道，但你一胖，大家就都知道了。'},
        {title:'84',content:'热恋时，情侣们常感叹上辈子积了什么德；结婚后，夫妻们常怀疑上辈子造了什么孽。'},
        {title:'85',content:'总有一款适合你：人丑却颜控，家贫还追星。友少偏爱宅，女汉发神经。蠢材不努力，懒虫盼巅峰。肥婆多吃货，单身总矫情。'},
        {title:'86',content:'要出去走走了，毕竟这么好的一张脸总是藏在家里，是社会的一大损失。'},
        {title:'87',content:'爱笑的人运气不会太差。说实话，如果一个人运气不好，我不知道他怎么笑得出来。'},
        {title:'88',content:'从小妈妈就跟我说不能交一些不三不四的朋友，我觉得我都有做到，而且做得非常好。因为我的朋友都很二。'},
        {title:'89',content:'科学研究证明，常年抽烟喝酒的人，患老年痴呆的概率较低，因为早死的概率较高。'},
        {title:'90',content:'有钱有脸叫男神，有钱没脸叫老公，有脸没钱叫蓝颜，至于没钱没脸的，对不起你是个好人。多么痛的领悟！'},
        {title:'91',content:'减肥的人千万别加什么减肥群，表面上看上去可以互相鼓励，其实没什么用，如果你不是那个最胖的，就会因为有人垫底而松懈。'},
        {title:'92',content:'有人说，凡事都是船到桥头自然直。可你这体重，没到桥头，船就沉了。'},
        {title:'93',content:'请大家一定要记住一句话：早饭一定要吃！当然，不是因为健康不健康，而是因为这是你一天当中最便宜的一顿饭！'},
        {title:'94',content:'减肥其实很简单，你要每天努力锻炼，不吃油腻的食物，持之以恒，日复一日年复一年，等你再照镜子时，你就会发现，对你而言，整容比减肥更重要。'},
        {title:'95',content:'看见别人都那么努力，那么勤奋，那么意气风发地走在成功的路上，你问问自己，难道就不想成为他们的绊脚石吗？'},
        {title:'96',content:'有生之年我希望别人用这三句话来羞辱我：“你怎么瘦成这个死样？”“你不就有几个臭钱吗？”“有个好对象了不起啊？'},
        {title:'97',content:'几岁就出来挣钱，从一无所有，发展到身无分文，再从身无分文拼搏到负债累累！这就是我，不一样的烟火，我就是我，我看到自己都冒火！'},
        {title:'98',content:'六月高考不努力，九月蓝翔做兄弟。兄弟，我在蓝翔等你…'},
        {title:'99',content:'女生不会做饭的话会出现什么情况呢？将来你的孩子长大后会说：好怀念小时候妈妈给我叫的外卖。'},
        ],
      wz_count:99,
      wz_currt:0,
      is_pc:false,
    };
  },
  created() {
    let rest = this.testPc();
    if(!rest.versions.mobile||rest.versions.iPad){
      //window.console.log('pc');
      this.is_pc = true;
    }else{
      //window.console.log('mobile');
      this.is_pc = false;
    }
    let num=this.randomNum(1,this.wz_count);
    this.wz_currt = num;
    //window.console.log(num,'num');
  },
  computed: {
  },

  methods: {
    testPc(){
      let browser={
      versions:function(){ 
            let u = navigator.userAgent; 
            return {  // 移动终端浏览器版本信息 
                  trident: u.indexOf('Trident') > -1,  // IE内核
                  presto: u.indexOf('Presto') > -1,    // Opera内核
                  webKit: u.indexOf('AppleWebKit') > -1,  // 苹果、谷歌内核
                  gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1,  // 火狐内核
                  mobile: !!u.match(/AppleWebKit.*Mobile.*/)||!!u.match(/AppleWebKit/)&&u.indexOf('QIHU')&&u.indexOf('Chrome')<0,  // 是否为移动终端  
                  ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),  // iOS终端
                  android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1,  // Android 终端或者 UC 浏览器
                  iPhone: u.indexOf('iPhone') > -1 || u.indexOf('Mac') > -1,  // 是否为 iPhone 或者 QQHD 浏览器
                  iPad: u.indexOf('iPad') > -1,   // 是否 iPad
                  webApp: u.indexOf('Safari') == -1,   // 是否WEB应该程序，没有头部与底部。
                  ua:u 
              };
          }(),
          
          language:(navigator.browserLanguage || navigator.language).toLowerCase()
        };
      return browser;
    },
    reflash(){
      this.reload()
    },
    randomNum(m,n){
      var num = Math.floor(Math.random()*(m - n) + n);
      return num;
    }
  }
};
</script>
<style scoped>
.body {
  font-size: 0.3rem;
}
p{ text-indent:2em;}
.each-row{
  font-size: 1px;;
}
.top-tag {
  font-size: 0.6rem;
  line-height: 0.8rem;
}

.sub-tag {
  font-size: 0.4rem;
  line-height: 0.6rem;
  color:black;
  background-color:yellow;
}

.red-tag {
  font-size: 0.4rem;
  line-height: 0.6rem;
  color:crimson;
  background-color:#DFE0E7;
}

.blue-tag {
  font-size: 0.4rem;
  line-height: 0.6rem;
  color:blue;
  background-color:white;
  margin-bottom: 10px;
}

.phone-tag {
  font-size: 0.55rem;
  line-height: 0.8rem;
  color:black;
  background-color:white;
  margin-top: 2px;
  margin-bottom: 3px;
}

.verify-tag {
  font-size: 0.55rem;
  line-height: 0.8rem;
  color:black;
  background-color:white;
  margin-top: 2px;
  margin-bottom: 3px;
}
.note-row{
  font-size: 0.35rem;
  color:gray;
  margin-bottom: 5px;
}
.dialog-button-bg {
  background: white;
  text-align: center;
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
</style>